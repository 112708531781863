import React from 'react'
import Layout from '../components/layout/layout'
import StakePage from '../components/partials/StakePage'


const Mint = () =>{
  return(
    <Layout page="stake">
      <StakePage />
    </Layout>
  )
}

export default Mint