import React, { useEffect, useState } from 'react'
import { DateRange } from 'react-date-range'
import Expand from 'react-expand-animated'
import { connect } from "react-redux"
import { historyData, stakeData } from './data';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './stakepage.scss'

const StakePage = ({
  web3, walletAddress, connected
}) => {
  const [price, setPrice] = useState(0.00)
  const [claimBalance, setClaimBalance] = useState(0.00)
  const [openRangeSetting, setOpenRangeSetting] = useState(false)
  const [openActionType, setOpenActionType] = useState(false)
  const [actionType, setActionType] = useState(null)
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);

  useEffect(() => {
    setPrice(0.22)
    setClaimBalance(50.03)
  }, [])

  return (
    <div className='stake-container w-full min-h-screen flex justify-center uppercase flex-col items-center font-staatliches pb-40' >
      <div className='container flex justify-start items-center pt-32 sm:pt-40 lg:pt-48 flex-col px-2 sm:px-4'>
        <img className='' src='/home_page/top_logo.png' alt='top-logo' />
        <p className='text-center mt-6 text-app-blue-50 xl:w-2/3 text-2xl sm:text-3xl xl:text-4xl '>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normaldistribution of letters, as opposed to using 'Content here, content here',</p>
        <div className='flex flex-col items-center justify-center mt-25 text-app-blue-50 gap-6'>
          <div className='flex items-center gap-6 justify-center px-4'>
            <img src='/stake_page/axe.png' className='w-14 h-14 lg:w-25 lg:h-25' alt='axe' />
            <div className='text-4xl sm:text-5xl lg:text-7xl flex gap-2'>
              <p>price:</p>
              <p>{price}</p>
              <p>eth</p>
            </div>
          </div>
          <div className='w-full flex gap-4'>
            <button className='btn-action lg:pt-3 lg:pb-5 pt-2 pb-3 text-3xl lg:text-5xl w-1/2'>
              buy
            </button>
            <button className='btn-action lg:pt-3 lg:pb-5 pt-2 pb-3 text-3xl lg:text-5xl w-1/2'>
              add
            </button>
          </div>
        </div>
        <div className='border-4 shadow-inner-xl flex text-app-blue-50 rounded-3xl w-full bg-app-blue-50 xl:w-2/3 mt-20'>
          <div className='bg-app-gray-900 flex items-center justify-center flex-col rounded-l-3xl w-1/3 sm:w-1/4 sm:px-2'>
            <p className='text-lg sm:text-2xl lg:text-4xl text-center'>available to claim</p>
            <p className='text-4xl sm:text-5xl lg:text-6xl text-center'>{claimBalance}</p>
            <p className='text-lg sm:text-2xl lg:text-4xl text-center lg:mt-1 sm:mb-2 lg:mb-3'>rrok</p>
            <button className='btn-action-white lg:pt-3 lg:pb-5 sm:pt-2 sm:pb-3 pb-1 text-2xl lg:text-5xl w-full text-app-gray-900'>
              claim
            </button>
          </div>
          <div className='flex flex-col divide-y-4 divide-app-gray-900 text-app-gray-900 w-2/3 sm:w-3/4 text-lg sm:text-2xl xl:text-4xl'>
            <div className='flex flex-col h-1/2 w-full justify-around px-2 md:px-10 lg:px-20 py-2 sm:py-4 xl:py-8'>
              <div className='md:w-3/4 flex flex-col gap-1 sm:gap-2 xl:gap-4'>
                <div className='flex justify-between items-center'>
                  <p className=' opacity-80'>wallet balance</p>
                  <p>xxxx.xx</p>
                </div>
                <div className='flex justify-between items-center'>
                  <p className=' opacity-80'>last claimed</p>
                  <p>xxxx</p>
                </div>
                <div className='flex justify-between items-center'>
                  <p className=' opacity-80'>total claimed</p>
                  <p>xxxx.xx</p>
                </div>
              </div>
            </div>
            <div className='flex flex-col h-1/2 w-full justify-around px-2 md:px-10 lg:px-20 py-2 sm:py-4 xl:py-8'>
              <div className='md:w-3/4 flex flex-col gap-1 sm:gap-2 xl:gap-4'>
                <div className='flex justify-between items-center'>
                  <p className=' opacity-80'>total staked</p>
                  <p>xxxx.xx</p>
                </div>
                <div className='flex justify-between items-center'>
                  <p className=' opacity-80'>avg.lock time</p>
                  <p>x days</p>
                </div>
                <div className='flex justify-between items-center'>
                  <p className=' opacity-80'>avg.apr</p>
                  <p>xxx.xx %</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full border-t border-b mt-32 py-3 lg:py-6'>
        <p className='text-4xl sm:text-5xl lg:text-7xl text-center text-app-blue-50 '>staking</p>
      </div>
      <div className='flex items-center justify-center py-3'>
        <button className='btn-action lg:pt-3 lg:pb-5 pt-2 pb-3 text-3xl lg:text-5xl px-12 lg:px-16 text-app-blue-50'>
          stake
        </button>
      </div>
      <div className='container px-2'>
        <div className='hidden sm:flex'>
          <table className='w-full text-xl md:text-2xl lg:text-3xl rounded-t-3xl rounded-b-lg overflow-hidden '>
            <thead>
              <tr className='bg-app-blue-dark text-app-blue-50 py-4  '>
                <th className='py-6 opacity-70'>pool</th>
                <th className='py-6 opacity-70'>amount</th>
                <th className='py-6 opacity-70'>staked</th>
                <th className='py-6 opacity-70'>apr%</th>
                <th className='py-6 opacity-70'>lock date</th>
                <th className='py-6 opacity-70'>unlock date</th>
                <th className='py-6 opacity-70'>action</th>
              </tr>
            </thead>
            <tbody className='rounded-b-3xl overflow-hidden'>
              {stakeData.map((data, idx) => (
                <tr className='text-app-blue-dark table-item' key={idx}>
                  <td className='py-4 text-center'>{data.pool}</td>
                  <td className='py-4 text-center'>{data.amount}</td>
                  <td className='py-4 text-center'>{data.staked}</td>
                  <td className='py-4 text-center'>{data.apr}</td>
                  <td className='py-4 text-center'>{data.lock}</td>
                  <td className='py-4 text-center'>{data.unlock}</td>
                  <td className='py-4 text-center'>{data.action}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='flex flex-col sm:hidden gap-3'>
          {stakeData.map((data, idx) => (
            <div className='flex rounded-2xl overflow-hidden border border-app-blue-dark' key={idx}>
              <div className='bg-app-blue-dark w-1/3 text-app-blue-50 text-center py-3'>
                <ul>
                  <li className='opacity-70'>pool</li>
                  <li className='opacity-70'>amount</li>
                  <li className='opacity-70'>staked</li>
                  <li className='opacity-70'>apr%</li>
                  <li className='opacity-70'>lock date</li>
                  <li className='opacity-70'>unlock date</li>
                  <li className='opacity-70'>action</li>
                </ul>
              </div>
              <div className='w-2/3 text-app-blue-dark text-right py-3'>
                <ul>
                  <li className='table-item pr-6'>{data.pool}</li>
                  <li className='table-item pr-6'>{data.amount}</li>
                  <li className='table-item pr-6'>{data.staked}</li>
                  <li className='table-item pr-6'>{data.apr}</li>
                  <li className='table-item pr-6'>{data.lock}</li>
                  <li className='table-item pr-6'>{data.unlock}</li>
                  <li className='table-item pr-6'>{data.action}</li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='w-full border-t border-b mt-32 py-3 lg:py-6'>
        <p className='text-4xl sm:text-5xl lg:text-7xl text-center text-app-blue-50 '>TRANSACTION HISTORY</p>
        <p className='hidden bg-gradient-to-b from-app-blue-300 to-app-blue-400'></p>
      </div>
      <div className='flex gap-8 text-app-blue-50 text-xl sm:text-3xl items-center justify-start container px-2 my-3'>
        <div className='relative' >
          <button className='rounded-full whitespace-nowrap bg-app-blue-dark flex gap-2 md:gap-4 py-1 sm:py-3 px-3 sm:px-10 items-center justify-center' onClick={(e) => { setOpenRangeSetting(!openRangeSetting) }}>
            <p>date range</p>
            <img src='/icons/ic_down.svg' alt='down icon' className='w-4 md:w-6' />
          </button>
          <Expand open={openRangeSetting} className="absolute z-30 mt-2 rounded-xl p-2 bg-app-blue-50">
            <DateRange
              editableDateInputs={true}
              onChange={item => setState([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
            />
          </Expand>
        </div>
        <div className='relative'>
          <button className='rounded-full whitespace-nowrap bg-app-blue-dark flex gap-2 md:gap-4 py-1 sm:py-3 px-3 sm:px-10 items-center justify-center z-30' onClick={() => setOpenActionType(!openActionType)}>
            <p>Action Type</p>
            <img src='/icons/ic_down.svg' alt='down icon' className='w-4 md:w-6' />
          </button>
          <Expand open={openActionType} className="bg-app-blue-50 absolute z-20 text-app-blue-dark w-full mt-2 rounded-xl px-2 border border-app-blue-200">
            <ul className='divide-y-2 divide-app-blue-200 items-center'>
              <li>
                <button className='py-3 w-full' onClick={() => { setActionType(1); setOpenActionType(false) }}>pending</button>
              </li>
              <li>
                <button className='py-3 w-full' onClick={() => { setActionType(2); setOpenActionType(false) }}>completed</button>
              </li>
            </ul>
          </Expand>
        </div>
      </div>
      <div className='container px-2'>
        <div className='hidden sm:flex'>
          <table className='w-full text-xl md:text-2xl lg:text-3xl rounded-t-3xl rounded-b-lg overflow-hidden'>
            <thead>
              <tr className='bg-app-blue-dark text-app-blue-50 py-4  '>
                <th className='py-6 opacity-70'>Date/Time</th>
                <th className='py-6 opacity-70'>action</th>
                <th className='py-6 opacity-70'>amount</th>
                <th className='py-6 opacity-70'>balance</th>
              </tr>
            </thead>
            <tbody className='rounded-b-3xl overflow-hidden'>
              {historyData.map((data, idx) =>
                <tr className='text-app-blue-dark table-item' key={idx}>
                  <td className='py-3 text-center'>{data.date}</td>
                  <td className='py-3 text-center'>{data.action}</td>
                  <td className='py-3 text-center'>{data.amount}</td>
                  <td className='py-3 text-center'>{data.balance}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='flex flex-col sm:hidden gap-3'>
          {historyData.map((data, idx) => (
            <div className='flex rounded-2xl border border-app-blue-dark overflow-hidden' key={idx}>
              <div className='bg-app-blue-dark w-1/3 text-app-blue-50 text-center py-3'>
                <ul>
                  <li className='opacity-70'>Date/Time</li>
                  <li className='opacity-70'>action</li>
                  <li className='opacity-70'>amount</li>
                  <li className='opacity-70'>balance</li>
                </ul>
              </div>
              <div className='w-2/3 text-app-blue-dark text-right py-3'>
                <ul>
                  <li className='table-item pr-6'>{data.date}</li>
                  <li className='table-item pr-6'>{data.action}</li>
                  <li className='table-item pr-6'>{data.amount}</li>
                  <li className='table-item pr-6'>{data.balance}</li>
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const stateProps = (state) => ({
  web3: state.web3,
  walletAddress: state.walletAddress,
  connected: state.connected
});

export default connect(stateProps, null)(StakePage);