export const stakeData = [
  {
    pool: "1",
    amount: "23end",
    staked: "100end",
    apr: "2.00%",
    lock: "00:12:21 apr 12",
    unlock: "00:12:21 apr 12",
    action: "pending",
  },
  {
    pool: "2",
    amount: "23end",
    staked: "100end",
    apr: "2.00%",
    lock: "00:12:21 apr 12",
    unlock: "00:12:21 apr 12",
    action: "pending",
  },
  {
    pool: "3",
    amount: "23end",
    staked: "100end",
    apr: "2.00%",
    lock: "00:12:21 apr 12",
    unlock: "00:12:21 apr 12",
    action: "pending",
  },
  {
    pool: "4",
    amount: "23end",
    staked: "100end",
    apr: "2.00%",
    lock: "00:12:21 apr 12",
    unlock: "00:12:21 apr 12",
    action: "pending",
  },
  {
    pool: "5",
    amount: "23end",
    staked: "100end",
    apr: "2.00%",
    lock: "00:12:21 apr 12",
    unlock: "00:12:21 apr 12",
    action: "pending",
  },
  {
    pool: "6",
    amount: "23end",
    staked: "100end",
    apr: "2.00%",
    lock: "00:12:21 apr 12",
    unlock: "00:12:21 apr 12",
    action: "pending",
  }
]

export const historyData = [
  {
    date:"00:12:21 apr 22",
    action:"pending",
    amount:"23 end",
    balance:"1000 end"
  },
  {
    date:"00:12:21 apr 22",
    action:"pending",
    amount:"23 end",
    balance:"1000 end"
  },
  {
    date:"00:12:21 apr 22",
    action:"pending",
    amount:"23 end",
    balance:"1000 end"
  },
  {
    date:"00:12:21 apr 22",
    action:"pending",
    amount:"23 end",
    balance:"1000 end"
  },
  {
    date:"00:12:21 apr 22",
    action:"pending",
    amount:"23 end",
    balance:"1000 end"
  },
  {
    date:"00:12:21 apr 22",
    action:"pending",
    amount:"23 end",
    balance:"1000 end"
  },
  {
    date:"00:12:21 apr 22",
    action:"pending",
    amount:"23 end",
    balance:"1000 end"
  },
  {
    date:"00:12:21 apr 22",
    action:"pending",
    amount:"23 end",
    balance:"1000 end"
  },
]
